import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Configurator from "./pages/Configurator";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CustomError from "./pages/404";
import Login from "./pages/Login";
import { v4 } from "uuid";
import AttemptLogin from "./pages/AttemptLogin";

function App() {
  
  const sessionId = v4();
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/attempt-login" element={<AttemptLogin />} />
          <Route path="/login/:id" element={<Login />} />
          <Route
            path="/customize-product/:id"
            element={<Configurator isEditMode={true} sessionId={sessionId} />}
          />
          <Route
            path="/:id"
            element={<Configurator isEditMode={false} sessionId={sessionId} />}
          />
          <Route path="/no-configurator-rules" element={<CustomError />} />
          <Route path="*" element={<CustomError />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
