import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
// import checkIcon from "../assets/icons/check.svg";
import {
  SET_CURRENT_STEP,
  SET_SELECTED_OPTION,
} from "../store/userConfiguration/configurationSlice";
import { useAppDispatch } from "../hooks/hooks";
import { StepType } from "../types";
import useUserConfiguration from "../utils/updateUserConfiguration";
import useCaptureEvent from "../hooks/useCaptureEvents";
import { useParams } from "react-router-dom";

const RenderThumbnails = ({
  option,
  step,
  index,
  sessionId,
  isEditMode,
}: {
  option: any;
  step: StepType;
  index: number;
  sessionId?: string;
  isEditMode?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const { fireEvent } = useCaptureEvent();
  const { name, thumbnail } = option;
  const {
    updateUserConfiguration,
    getSelectedOption,
    getSelectedOptionForCustomStep,
  } = useUserConfiguration();
  const [isSelected, setIsSelected] = useState(false);
  const { id } = useParams();
  useEffect(() => {
    const selectedOption =
      step.type === "custom_step"
        ? getSelectedOptionForCustomStep(option.name)
        : getSelectedOption(step.name);
    if (selectedOption === option.name) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  }, [
    getSelectedOption,
    getSelectedOptionForCustomStep,
    option.name,
    step.name,
    step.type,
  ]);

  const [hoverStyle, setHoverStyle] = useState({});

  const handleMouseEnter = () =>
    setHoverStyle({
      transition: "all .2s ease-in-out",
      scale: "#f8f9fa",
      transform: "scale(1.2)", // Example hover style
      // Add other hover styles here
    });

  const handleMouseLeave = () => setHoverStyle({});

  return (
    <Col
      xs={3}
      className="d-flex flex-column align-items-center "
      onClick={async () => {
        if (step.type !== "custom_step") {
          dispatch(SET_CURRENT_STEP(step));
          dispatch(SET_SELECTED_OPTION(option));
          updateUserConfiguration(step.name, name);
        } else {
          dispatch(SET_CURRENT_STEP(step));
          console.log(option, "option");

          if (
            !getSelectedOptionForCustomStep(option.name) ||
            getSelectedOptionForCustomStep(option.name).toLowerCase() === "none"
          ) {
            dispatch(SET_SELECTED_OPTION(option));
            updateUserConfiguration(name, name);
          } else {
            if (step.custom_step_type === "single") {
              dispatch(SET_SELECTED_OPTION(option));
              updateUserConfiguration(name, name);
            } else {
              dispatch(SET_SELECTED_OPTION(option));
              updateUserConfiguration(name, "None");
            }
          }
        }
        const parameters = {
          step_name: step.name,
          step_type: step.type,
          option_name: option.name,
          product_id: id,
          session_id: sessionId,
          user_type: isEditMode ? "Admin" : "User",
        };
        await fireEvent("CUSTOMIZER_PRODUCT_MODIFICATION", parameters);
      }}
      id={`${step.name}-${option.name}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        cursor: "pointer",
        ...hoverStyle, 
      }}
    >
      <img
        src={thumbnail}
        className={`rounded-circle border border-2 ${
          isSelected ? "border-dark border-3" : " "
        } ${index > 3 && "mt-3"}`}
        width={"55px"}
        height={"55px"}
        alt={name}
      />
      <span
        style={{
          fontSize: "10px",
          textAlign: "center",
          color: "#717070",
          paddingTop: "5px",
          fontWeight: "700",
        }}
      >
        {name}
      </span>
    </Col>
  );
};

export default RenderThumbnails;
