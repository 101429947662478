import { useQuery } from "react-query";
import { fetchProduct } from "../api/api";

const useProduct = (id: string | undefined | null) => {
  const { data, error, isLoading } = useQuery(
    "product",
    () => fetchProduct(id as string),
    {
      select: (data) => {
        return data;
      },
    }
  );
  return { data: data, error, isLoading };
};

export default useProduct;
