import React, { useEffect, useState } from "react";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import { Card, Form } from "react-bootstrap";
import { StepType } from "../types";
import RenderOptions from "./RenderOptions";
// import { shouldDisplayCard } from "../utils/shouldDisplayCard";
import useUserConfiguration from "../utils/updateUserConfiguration";
import editIcon from "../assets/icons/editIcon.svg";
import { useAppDispatch } from "../hooks/hooks";
import "./styles/SideBarCard.css";
import {
  SET_CURRENT_STEP,
  SET_EDITING_STEP,
  SET_EDITING_STEP_INDEX,
  SET_SELECTED_OPTION,
} from "../store/userConfiguration/configurationSlice";
interface SideBarCardProps {
  step: StepType;
  userConfiguration: any;
  cardIndex: number;
  handleMoveStep: (stepIndex: number, direction: "up" | "down") => void;
  isEditMode?: boolean;
  isLast: boolean;
  sessionId: string;
  product?:any;
}
const SideBarCard: React.FC<SideBarCardProps> = ({
  step,
  userConfiguration,
  cardIndex,
  handleMoveStep,
  isEditMode,
  isLast,
  sessionId,
  product
}) => {
  const { name, options } = step;
  const { getSelectedOption, updateUserConfiguration } = useUserConfiguration();
  // const [shouldShowCard, setShouldShowCard] = useState(true);
  const [showPart, setShowPart] = useState("hide");


  // useEffect(() => {
  //   if (step.displayCondition) {
  //     const showCard = shouldDisplayCard(
  //       step.displayCondition,
  //       userConfiguration
  //     );
  //     setShouldShowCard(showCard);
  //   }
  // }, [step, userConfiguration]);


  const dispatch = useAppDispatch();
  useEffect(() => {
    if (step?.type !== "custom_step" && step.defaultOption) {
      ((() => {
        if (step.type !== "custom_step") {
          if (step.defaultOption?.name) {
            const intervalId = setInterval(() => {
              const elt = document.getElementById(`${step.name}-${step.defaultOption?.name}`)
              if (elt) {
                elt.click()
                clearInterval(intervalId)
              }
            }, 1000)
          }
        }

      })())
    }


  }, [step.defaultOption, step.name, step.type])


  return (
    <div
      className={`${!isEditMode
        ? !step.isHidden && step.id
          ? "d-flex mb-4"
          : "d-none"
        : "d-flex mb-4"
        } ps-3`}
      id="sidebarCard"
    >
      {step.type !== "hide_show_part_selector" ? (
        <Card
          style={{
            width: "100%",
            border: "none",
            backgroundColor:product?.application === "64803030c8ee4fc7ad657576" ? "#f0f0f0":"#ffffff", //for spacewood
          }}
        >
          <Card.Body className="text-start py-1 px-0 mx-0">
            <Card.Title className="mb-4 w-100 d-flex justify-content-between align-items-center">
              <h6>
                {name}{" "}
                {name !== "Dimensions" && (
                  <span className="fw-light fs-6">
                    {getSelectedOption(name) && `| ${getSelectedOption(name)}`}
                  </span>
                )}
              </h6>
              {isEditMode && (
                <div>
                  <button
                    className="border-0 bg-transparent"
                    onClick={() => {
                      handleMoveStep(cardIndex, "up");
                    }}
                    disabled={cardIndex === 0}
                  >
                    <FaChevronUp width={14} height={14} />
                  </button>
                  <button
                    className="border-0 bg-transparent"
                    onClick={() => {
                      handleMoveStep(cardIndex, "down");
                    }}
                    disabled={isLast}
                  >
                    <FaChevronDown width={14} height={14} />
                  </button>
                  <button
                    className="border-0 bg-transparent "
                    onClick={() => {
                      dispatch(SET_EDITING_STEP(step));
                      dispatch(SET_EDITING_STEP_INDEX(cardIndex));
                    }}
                  >
                    <img src={editIcon} alt="editIcon" />
                  </button>
                </div>
              )}
            </Card.Title>
            <RenderOptions
              cardOptions={options}
              step={step}
              stepIndex={cardIndex}
              sessionId={sessionId}
              isEditMode={isEditMode}
              selectorType={
                step.type === "dimension_selector" && step.selector_type
                  ? step.selector_type
                  : undefined
              }
            />
          </Card.Body>
        </Card>
      ) : (
        <Card
          style={{
            width: "100%",
            border: "none",
          }}
        >
          <Card.Body className="text-start py-1 px-0 mx-0">
            <Card.Title className="mb-4 w-100 d-flex justify-content-between align-items-center">
              <h6>
                {name}{" "}
                {name !== "Dimensions" &&
                  step.type !== "hide_show_part_selector" && (
                    <span className="fw-light fs-6">
                      {getSelectedOption(name) &&
                        `| ${getSelectedOption(name)}`}
                    </span>
                  )}
              </h6>
              {isEditMode && (
                <div>
                  <button
                    className="border-0 bg-transparent"
                    onClick={() => {
                      handleMoveStep(cardIndex, "up");
                    }}
                    disabled={cardIndex === 0}
                  >
                    <FaChevronUp width={14} height={14} />
                  </button>
                  <button
                    className="border-0 bg-transparent"
                    onClick={() => {
                      handleMoveStep(cardIndex, "down");
                    }}
                    disabled={isLast}
                  >
                    <FaChevronDown width={14} height={14} />
                  </button>
                  <button
                    className="border-0 bg-transparent "
                    onClick={() => {
                      dispatch(SET_EDITING_STEP(step));
                      dispatch(SET_EDITING_STEP_INDEX(cardIndex));
                    }}
                  >
                    <img src={editIcon} alt="editIcon" />
                  </button>
                </div>
              )}
            </Card.Title>
            <Form className="mt-0 pt-0 ms-3">
              <div key={`default`} className="mb-3">
                <label className="switch" >
                  <input
                    type="checkbox"
                    checked={userConfiguration[step.id as string]?.name === "show"}
                    onChange={() => {
                      setShowPart((prevState) => {
                        const currState = prevState === "show" ? "hide" : "show"
                        updateUserConfiguration(step.name, currState)
                        return currState
                      }
                      );
                      dispatch(SET_CURRENT_STEP(step));
                      dispatch(SET_SELECTED_OPTION(options[0]));
                    }}
                  ></input>
                  <span className="slider round"></span>
                </label>
              </div>
            </Form>
          </Card.Body>
        </Card>
      )}
    </div>
  );
};

export default SideBarCard;
