import React, { useEffect, useState } from "react";
import SideBarCard from "../components/SideBarCard";
import { useAppSelector } from "../hooks/hooks";
import { StepType } from "../types";
import { Col } from "react-bootstrap";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { TOGGLE_ADDING_STEP } from "../store/userConfiguration/configurationSlice";
import { getVariants, patchProductConfiguratorRules } from "../api/api";
import VariantsCard from "../components/VariantsCard";
import useIsPortrait from "../hooks/useIsPortrait";
import { SET_CONFIGURATOR_RULES } from "../store/product/productSlice";
import Header from "./Header";
import { useParams, useSearchParams } from "react-router-dom";

interface SideBarProps {
  configData: any;
  isBottomBarOpen: boolean;
  setIsBottomBarOpen: (isBottomBarOpen: boolean) => void;
  isEditMode?: boolean;
  sessionId: string;
  productViewerRef: any;
}

const SideBar: React.FC<SideBarProps> = ({
  configData,
  isBottomBarOpen,
  setIsBottomBarOpen,
  isEditMode,
  sessionId,
  productViewerRef,
}) => {
  const dispatch = useDispatch();
  const userConfiguration = useAppSelector(
    (state) => state.configuration.userConfiguration
  );
  const product = useAppSelector((state) => state.product.product);
  const { id } = useParams();
  const disabledFeatures = useAppSelector(
    (state) => state.configuration.disabledFeatures
  );
  const [variantsStep, setVariantsStep] = useState<any>(undefined);
  const isPortrait = useIsPortrait();
  useEffect(() => {
    if (id && product) {
      (async () => {
        const variantsArray: any[] = await getVariants(id);
        const variantsArrayMapped = Array.isArray(variantsArray)
          ? variantsArray.map((variant: any) => {
              const { name, three_dee_model, thumbnail } = variant;
              const { glb } = three_dee_model;
              return { name, thumbnail, rules: [{ glb }] };
            })
          : [];
        const defaultVariant = {
          name: "Default",
          thumbnail: product.thumbnail,
          rules: [{ glb: product.three_dee_model.glb }],
        };
        const variantsStepTemp = {
          name: "Variant",
          type: "variant_selector",
          node: ["Scene"],
          options: [defaultVariant, ...variantsArrayMapped],
        };
        setVariantsStep(variantsStepTemp);
      })();
    }
  }, [id, product]);

  useEffect(() => {
    if (disabledFeatures.length > 0 && configData) {
      const tempConfigData = { ...configData };
      const tempSteps = [...tempConfigData.steps];

      for (let i of tempSteps) {
        if (disabledFeatures.includes(i.id)) {
          const disableStepIndex = tempSteps.indexOf(i);
          const tempStep = { ...tempSteps[disableStepIndex] };
          tempStep.isHidden = true;
          tempSteps[disableStepIndex] = tempStep;
        }
      }
      tempConfigData.steps = [...tempSteps];
      dispatch(SET_CONFIGURATOR_RULES(tempConfigData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabledFeatures, dispatch]);

  const handleMoveStep = async (
    stepIndex: number,
    direction: "up" | "down"
  ) => {
    const tempConfigData = { ...configData };
    const tempSteps = [...tempConfigData.steps];
    let requiredTempStep;
    if (direction === "up") {
      requiredTempStep = tempSteps[stepIndex - 1];
      tempSteps[stepIndex - 1] = tempConfigData.steps[stepIndex];
    } else {
      requiredTempStep = tempSteps[stepIndex + 1];
      tempSteps[stepIndex + 1] = tempConfigData.steps[stepIndex];
    }
    tempSteps[stepIndex] = requiredTempStep;
    tempConfigData.steps = tempSteps;
    if (id) {
      try {
        await patchProductConfiguratorRules(id, tempConfigData);
        dispatch(SET_CONFIGURATOR_RULES(tempConfigData));
      } catch (error) {
        console.error(error);
        alert(JSON.stringify(error));
      }
    }
  };

  const [searchParams] = useSearchParams();
  const enableHeader = searchParams.get("enableAddtoCart")
    ? JSON.parse(searchParams.get("enableAddtoCart")!)
    : false;

  const [hoverStyle, setHoverStyle] = useState({});

  const handleMouseEnter = () =>
    setHoverStyle({
      transition: "all .2s ease-in-out",
      scale: "#f8f9fa",
      transform: "scale(1.1)", // Example hover style
      // Add other hover styles here
    });

  const handleMouseLeave = () => setHoverStyle({});
  return (
    <div
      className={`position-fixed bg-white  ${
        isBottomBarOpen && "sidebarActive"
      } 
      ${product?.application === "64803030c8ee4fc7ad657576" ? "" : "border"} 
      `} //for spacewood
      style={{ zIndex: "102", right: 0 }}
      id="sidebar"
    >
      <div
        style={{
          position: "absolute",
          width: "97%",
          display: "block",
          // margin: "auto",
          alignItems: "center",
        }}
      >
        {product && !isEditMode && (
          <Header
            productViewerRef={productViewerRef}
            sessionId={sessionId}
            isEditMode={isEditMode}
            product={product}
            enableHeader={enableHeader}
            showAddToCart={isPortrait ? false : true}
          />
        )}
      </div>
      <Col
        className="position-absolute d-flex d-md-none justify-content-center w-100"
        style={{ zIndex: "103", left: 0, top: "-5%" }}
      >
        <button
          className="btn border"
          style={{ background: "#dbdbdb" }}
          onClick={() => {
            setIsBottomBarOpen(!isBottomBarOpen);
          }}
        >
          {isBottomBarOpen ? (
            <FaChevronDown width={14} height={14} className="mx-1" />
          ) : (
            <FaChevronUp width={14} height={14} className="mx-1" />
          )}
        </button>
      </Col>
      <Col
        className={`sidebarCardCol pt-2 pt-md-0 pb-3 ${
          isEditMode && "editingCustomizations"
        } ${isEditMode && isPortrait && "sidebarCardColMobileEdit"}`}
        style={{
          backgroundColor:  product?.application === "64803030c8ee4fc7ad657576" ? "#f0f0f0": "#ffffff",
        }} //for spacewood
      >
        <h5
          className="text-start p-3 pt-4 font-weight-bolder"
          style={{
            fontSize: "24px",
          }}
        >
          Customizations
        </h5>
        <div
          style={{
            width: "100%",
            backgroundColor:  product?.application === "64803030c8ee4fc7ad657576" ? "#f0f0f0": "#ffffff",
            // border:"blue 2px solid",
            height: "90%",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          {variantsStep && variantsStep.options.length > 1 && (
            <VariantsCard step={variantsStep} sessionId={sessionId} />
          )}
          {console.log(userConfiguration, "userConfiguration")}

          {configData ?
            (configData.steps.length > 0 ? (
              configData.steps.map((step: StepType, index: number) => {
                return (
                  <SideBarCard
                    key={index}
                    step={step}
                    userConfiguration={userConfiguration}
                    cardIndex={index}
                    isEditMode={isEditMode}
                    handleMoveStep={handleMoveStep}
                    sessionId={sessionId}
                    isLast={index === configData.steps.length - 1}
                    product = {product}
                  />
                );
              })
            ) : (
              <div
                style={{
                  display: "flex",
                  height: "50%",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                  margin: "auto",
                }}
              >
                <div
                  style={{
                    display: "block",
                    margin: "auto",
                  }}
                >
                  {/* <img src="/images/no-products.gif" alt="no products" width={250} /> */}
                </div>
                <div>
                  <p style={{ textAlign: "center" }}>
                    <b>{"There are no customizations added"}</b>
                  </p>
                </div>
              </div>
            )):(

              <div
                style={{
                  display: "flex",
                  height: "50%",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                  margin: "auto",
                }}
              >
                <div
                  style={{
                    display: "block",
                    margin: "auto",
                  }}
                >
                  {/* <img src="/images/no-products.gif" alt="no products" width={250} /> */}
                </div>
                <div>
                  <p style={{ textAlign: "center" }}>
                    <b>{"Loading..."}</b>
                  </p>
                </div>
              </div>
            )}
        </div>
        {isPortrait && isEditMode && (
          <div className="d-flex flex-column px-3">
            <button
              className="btn border-0 btn-dark mt-4 w-100"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              style={{ background: "#A2DB35", ...hoverStyle }}
              onClick={() => {
                dispatch(TOGGLE_ADDING_STEP());
              }}
            >
              Add Customization
            </button>
          </div>
        )}
      </Col>
      {isEditMode && !isPortrait && (
        <div className="d-flex flex-column border-top px-3">
          <button
            className="btn border-0 btn-dark mt-4 w-100"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{ background: "#A2DB35", ...hoverStyle }}
            onClick={() => {
              dispatch(TOGGLE_ADDING_STEP());
            }}
          >
            Add Customization
          </button>
        </div>
      )}
    </div>
  );
};

export default SideBar;
