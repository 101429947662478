import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const removeToken = (token: any) =>
  token?.includes("Token") ? token.split("Token ")[1] : token;

function AttemptLogin() {
  const [productId, setProductId] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const onMessageReceived = (event: MessageEvent) => {
      let data = null;
      try {
        data = JSON.parse(event.data);
      } catch (e) {
        console.error("Error parsing event data", e);
        return; 
      }

      if (data && data.event_name === "ATTEMPT_LOGIN") {
        setProductId(data.data.product_id.toString());
        localStorage.setItem("parent_auth", removeToken(data.data.auth_token));
        localStorage.setItem("parent_userId", data.data.account_id.toString());
        localStorage.setItem(
          "parent_appId",
          data.data.application_id.toString()
        );
        window.parent.postMessage(
          JSON.stringify({ event_name: "ACKNOWLEDGE" }),
          "*"
        );
      }
    };

    window.addEventListener("message", onMessageReceived);

    return () => {
      window.removeEventListener("message", onMessageReceived);
    };
  }, []);

  useEffect(() => {
    if (productId) {
      navigate(`/customize-product/${productId}`, { replace: true });
    }
  }, [productId, navigate]);

  return <div id="emptyDiv">{productId ? null : "loading..."}</div>;
}

export default AttemptLogin;
