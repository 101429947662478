import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../hooks/hooks";
// import { useNavigate } from "react-router-dom";
import useDidWindowResize from "../hooks/useDidWindowResize";
import {
  SET_CONFIGURATOR_RULES,
  SET_PRODUCT,
} from "../store/product/productSlice";
import { useDispatch } from "react-redux";
import { isUserLoggedIn } from "../utils/utils";
import { getMaterial, getPart } from "../api/api";
import { Viewer3D } from "hello-3d-sdk/packages/sdk";
import useProduct from "../customHooks/useProduct";

const createMaterialRuleJSON = (
  textureUrl: string,
  materialName: string,
  metalnessUrl?: string,
  normalUrl?: string,
  roughnessUrl?: string,
  alphaUrl?: string
) => {
  return {
    id: "afba2240-cc6f-464e-a1ea-1569cd89a00e",
    type: "Manual",
    actions: [
      {
        parameters: {
          material: {
            name: materialName,
            texture: textureUrl,
            ...(metalnessUrl && { metalnessMap: metalnessUrl }),
            ...(normalUrl && { normalMap: normalUrl }),
            ...(roughnessUrl && { roughnessMap: roughnessUrl }),
            ...(alphaUrl && { alphaMap: alphaUrl }),
            center: {
              x: 0.5,
              y: 0.5,
            },
          },
          repeat: {
            x: 0.1,
            y: 0.1,
          },
        },
        type: "ChangeMaterial",
      },
    ],
  };
};

const Preview = ({
  configData,
  productViewerRef,
  isBottomBarOpen,
  autoRotateParts,
}: {
  configData: any;
  productViewerRef: React.MutableRefObject<any>;
  isBottomBarOpen: boolean;
  autoRotateParts: boolean | undefined;
}) => {
  const userConfiguration: any = useAppSelector(
    (state) => state.configuration.userConfiguration
  );

  const application = useAppSelector(
    (state) => state.product.product?.application
  );

  const [productViewer, setProductViewer] = useState<any>();
  const prevRules = useRef<any>({});
  const currentStep = useAppSelector(
    (state) => state.configuration.currentStep
  );
  const selectedOption = useAppSelector(
    (state) => state.configuration.selectedOption
  );
  const { id } = useParams();
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const resizeValue = useDidWindowResize();

  const { data } = useProduct(id as string);

  const hasObjectValueChanged = useCallback((value: any, prevValue: any) => {
    if (JSON.stringify(value) === JSON.stringify(prevValue)) {
      return false;
    }
    return true;
  }, []);

  let globalSteps: any[] = [];
  let globalSelectedOptions: any[] = [];

  const getRules = () => {
    let rules = selectedOption && selectedOption.rules;

    globalSteps.push(currentStep);
    globalSelectedOptions.push(selectedOption);

    if (selectedOption?.enableExtraCustomisations?.executableSteps) {
      globalSteps = globalSteps.concat(
        ...selectedOption?.enableExtraCustomisations?.executableSteps.map(
          (step: any) => {
            return stepsData.find(
              (stepData: any) => stepData.name === step.name
            );
          }
        )
      );
      globalSelectedOptions = globalSelectedOptions.concat(
        ...selectedOption?.enableExtraCustomisations?.executableSteps.map(
          (step: any) => {
            return step?.option;
          }
        )
      );
      rules = rules.concat(
        ...selectedOption?.enableExtraCustomisations?.executableSteps.map(
          (step: any) => {
            return step?.option?.rules;
          }
        )
      );
    }
    return rules;
  };

  useEffect(() => {
    if (productViewerRef.current) {
      setProductViewer(productViewerRef);
    }
  }, [productViewerRef]);
  useEffect(() => {
    const rules = getRules();
    if (
      selectedOption?.enableExtraCustomisations?.disabledSteps ||
      selectedOption?.enableExtraCustomisations?.enabledSteps
    ) {
      let tempConfigData = { ...configData };
      const steps = configData.steps;
      const disabledSteps =
        selectedOption.enableExtraCustomisations.disabledSteps;

      const enabledSteps =
        selectedOption.enableExtraCustomisations.enabledSteps;

      const stepsToDisable = steps.map((step: any) => {
        if (disabledSteps.includes(step.name)) {
          step = { ...step, isHidden: true };
        } else if (enabledSteps.includes(step.name)) {
          step = { ...step, isHidden: false };
        }
        return step;
      });
      tempConfigData.steps = stepsToDisable;
      dispatch(SET_CONFIGURATOR_RULES(tempConfigData));
    }
    if (rules.length > 0) {
      (async () => {
        Object.keys(rules).forEach(async (key: any) => {
          if (hasObjectValueChanged(rules[key], prevRules.current[key])) {
            if (rules.length > 0) {
              try {
                await runRules(rules);
              } catch (error) {
                console.error(error);
              }
            }
          } else if (currentStep?.type === "hide_show_part_selector") {
            await runRules(rules);
          } else {
            await runRules(rules);
          }
        });
        prevRules.current = rules;
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userConfiguration, selectedOption]);

  useEffect(() => {
    window.dispatchEvent(new Event("resize"));
    return () => {
      window.dispatchEvent(new Event("resize"));
    };
  }, [isBottomBarOpen, resizeValue]);

  const transformRules = async (rules: any, product: any) => {
    let temp = { ...rules };

    const updateMaterialStep = async (step: any) => {
      if (step.material_id) {
        try {
          const resMaterial = await getMaterial(
            product.application as string,
            step.material_id
          );

          const updatedOptions = resMaterial.textures.map(
            (option: any, index: number) => {
              const existingOption = step.options.find(
                (o: any) => o.name === option.name
              );
              return {
                ...existingOption,
                ...option,
                rules: [
                  {
                    textureUrl: option.base,
                    ...(option.normal && { normalUrl: option.normal }),
                    ...(option.metalness && { metalnessUrl: option.metalness }),
                    ...(option.roughness && { roughnessUrl: option.roughness }),
                    ...(option.alpha && { roughnessUrl: option.alpha }),
                  },
                ],
              };
            }
          );

          return {
            ...step,
            options: updatedOptions,
          };
        } catch (error) {
          console.error("Error fetching material:", error);
          return {
            ...step,
            options: step.options,
          };
        }
      }

      return step;
    };

    const updatePartStep = async (step: any) => {
      if (step.part_id) {
        try {
          const res = await getPart(
            product.application as string,
            step.part_id
          );

          const updatedOptions = [
            {
              name: "None",
              thumbnail: "https://i.ibb.co/whY06SN/none.jpg",
              default: true,
              rules: [
                {
                  pid: "",
                },
              ],
            },
          ].concat(
            res.options.map((option: any, index: number) => {
              const existingOption = step.options.find(
                (o: any) => o.name === option.name
              );
              console.log(existingOption, "existingOption");

              return {
                ...existingOption,
                ...option,
                rules: [
                  {
                    glb: option.glb,
                  },
                ],
              };
            })
          );

          return {
            ...step,
            options: updatedOptions,
          };
        } catch (error) {
          console.error("Error fetching part:", error);
          return {
            ...step,
            options: step.options,
          };
        }
      }

      return step;
    };

    for (let i = 0; i < temp.steps.length; i++) {
      if (temp.steps[i].type === "material_selector") {
        const updatedStep = await updateMaterialStep(temp.steps[i]);
        temp = {
          ...temp,
          steps: [
            ...temp.steps.slice(0, i),
            updatedStep,
            ...temp.steps.slice(i + 1),
          ],
        };
      } else if (temp.steps[i].type === "part_selector") {
        const updatedStep = await updatePartStep(temp.steps[i]);
        temp = {
          ...temp,
          steps: [
            ...temp.steps.slice(0, i),
            updatedStep,
            ...temp.steps.slice(i + 1),
          ],
        };
      }
    }
    dispatch(SET_CONFIGURATOR_RULES(temp));
  };

  const stepsData = useAppSelector(
    (state) => state.product.configuratorRules?.steps
  );

  useEffect(() => {
    data && dispatch(SET_PRODUCT(data));

    if (id) {
      if (data && (!data.configurator_rules || !data.configurator_rules?.json )) {
        dispatch(
          SET_CONFIGURATOR_RULES({
            base_model: {
              type: "single",
              pid: id,
            },
            steps: [],
          })
        );
      } else {
        data && data?.configurator_rules &&
          transformRules(JSON.parse(data?.configurator_rules?.json), data);
      }
    }
  }, [data]);

  const hideNode = useCallback(
    (nodeName: string) => {
      const ruleJSON = {
        id: "4175419f-ace6-4ca4-bf2d-bfe9b53f75c1",
        type: "Manual",
        actions: [
          {
            parameters: {
              node: nodeName,
            },
            type: "HideNode",
            id: "e526d733-de1c-4b6c-bf6a-b61688d2c807",
          },
        ],
      };

      if (productViewerRef.current) {
        productViewerRef.current?.runCustomRule({
          rule: ruleJSON,
          node: null,
        });
      }
    },
    [productViewerRef]
  );

  const showNode = useCallback(
    (nodeName: string) => {
      let ruleJSON = {
        id: "4175419f-ace6-4ca4-bf2d-bfe9b53f75c1",
        type: "Manual",
        actions: [
          {
            parameters: {
              node: nodeName,
            },
            type: "ShowNode",
            id: "e526d733-de1c-4b6c-bf6a-b61688d2c807",
          },
        ],
      };

      if (productViewerRef.current) {
        productViewerRef.current?.runCustomRule({
          rule: ruleJSON,
          node: null,
        });
      }
    },
    [productViewerRef]
  );

  const replacePartGlb = useCallback(
    (nodes: string[], url: string) => {
      const getReplacePartGlb = (partUrl: string, nodes: string[]) => {
        return {
          parameters: {
            part: partUrl,
            nodes: nodes,
            position: {
              position: {
                x: 0,
                y: 0,
                z: 0,
              },
              scale: {
                x: "1",
                y: "1",
                z: "1",
              },
              rotation: {
                x:
                  autoRotateParts || application === "63fc4446115739a41c906132"
                    ? -90
                    : 0,
                y: 0,
                z: 0,
              },
            },
          },
          type: "ReplacePart",
          id: "6cc506e6-0ce8-41dd-8990-c0430fd1f141",
        };
      };
      return new Promise((resolve, reject) => {
        const actions = [getReplacePartGlb(url, nodes)];

        let ruleJSON = {
          id: "21cbc580-8270-4d23-bc61-f0d27a711af3",
          iconText: "init",
          type: "Manual",
          actions,
        };

        if (productViewerRef.current) {
          productViewerRef.current?.runCustomRule({
            rule: ruleJSON,
            node: null,
          });
          resolve(true);
        } else {
          reject(true);
        }
      });
    },
    [application, autoRotateParts, productViewerRef]
  );

  // const replaceSceneGlb = useCallback(
  //   (nodes: string[], url: string) => {
  //     return new Promise((resolve, reject) => {
  //       const actions = [getReplaceSceneGlb(url, nodes)];

  //       let ruleJSON = {
  //         id: "9d8c67d1-29f4-4697-b1a0-5aaae08d61b7",
  //         iconText: "ChangeLeg",
  //         type: "Menu",
  //         actions,
  //       };

  //       if (productViewer?.viewer) {
  //         productViewer?.viewer?.runCustomRule(
  //           ruleJSON,
  //           () => {
  //             resolve(true);
  //           },
  //           () => {
  //             reject(false);
  //           }
  //         );
  //       }
  //     });
  //   },
  //   [productViewer?.viewer]
  // );

  const applyMaterial = useCallback(
    (
      nodeName: string,
      textureUrl: string,
      metalnessUrl?: string,
      normalUrl?: string,
      roughnessUrl?: string,
      alphaUrl?: string
    ) => {
      const ruleJSON = createMaterialRuleJSON(
        textureUrl,
        nodeName,
        metalnessUrl,
        normalUrl,
        roughnessUrl,
        alphaUrl
      );

      if (productViewerRef.current) {
        productViewerRef.current?.runCustomRule({
          rule: ruleJSON,
          node: null,
        });
      }
    },
    [productViewerRef]
  );

  const runRules = useCallback(
    async (rules: any[]) => {
      const hideParts = (nodes: string[]) => {
        for (let node of nodes) {
          hideNode(node);
        }
      };
      const showParts = (nodes: string[]) => {
        for (let node of nodes) {
          showNode(node);
        }
      };

      if (currentStep && selectedOption) {
        const rulesLength = rules.length;
        for (let index = 0; index < rulesLength; index++) {
          const customIndex =
            currentStep.type === "custom_step" ? index + 1 : index;
          const rule = rules[index];
          const nodes: string[] =
            typeof currentStep.node === "string"
              ? [...globalSteps[customIndex].node]
              : globalSteps[customIndex].node;

          if (globalSteps[index].type === "custom_step") {
            const selectedDummyStep = stepsData.find(
              (step: any) =>
                step.name.toLowerCase() === selectedOption.name.toLowerCase()
            );

            if (
              userConfiguration[selectedDummyStep.id].name.toLowerCase() ===
              "none"
            ) {
              if (nodes && nodes.length > 0) {
                setTimeout(() => {
                  hideParts(nodes);
                }, 200);
              }
            } else {
              if (nodes && nodes.length > 0) {
                showParts(nodes);
                try {
                  if (rule.glb) {
                    await replacePartGlb(nodes, rule.glb);
                  }
                } catch (error) {
                  console.error(error);
                }
              }
            }
            if (
              selectedDummyStep.custom_json &&
              JSON.parse(selectedDummyStep.custom_json)
            ) {
              const customRules = JSON.parse(selectedDummyStep.custom_json)
                .conditions
                ? [...JSON.parse(selectedDummyStep.custom_json).conditions]
                : [...JSON.parse(selectedDummyStep.custom_json)];

              for (let i = 0; i < customRules.length; i++) {
                const CustomRuleNodes: string[] =
                  typeof customRules[i].node === "string"
                    ? [customRules[i].node]
                    : customRules[i].node;
                if (customRules[i].condition.length === 1) {
                  if (
                    customRules[i].condition[0]?.name ??
                    userConfiguration[customRules[i].condition[0].id].name ===
                      customRules[i].condition[0].name
                  ) {
                    // console.log(customRules[i], `${i} test 1`);
                    const parameters = customRules[i].rules
                      ? customRules[i].rules
                      : customRules[i].parameters;
                    for (let j = 0; j < parameters.length; j++) {
                      if (
                        customRules[i].type === "show_hide_step" &&
                        userConfiguration[
                          customRules[i].condition[0].id
                        ].name.toLowerCase() ===
                          customRules[i].condition[0].name.toLowerCase()
                      ) {
                        const tempConfigData = { ...configData };
                        const tempSteps = [...tempConfigData.steps];
                        const enableStepsArray = parameters[j].enable_steps
                          ? parameters[j].enable_steps
                          : parameters[j].enableCustomizations;
                        const disableStepsArray = parameters[j].disable_steps
                          ? parameters[j].disable_steps
                          : parameters[j].disableCustomizations;
                        if (enableStepsArray.length > 0) {
                          for (let m of tempSteps) {
                            if (enableStepsArray.includes(m.id)) {
                              const enableStepIndex = tempSteps.indexOf(m);

                              const tempStep = {
                                ...tempSteps[enableStepIndex],
                              };
                              tempStep.isHidden = false;
                              tempSteps[enableStepIndex] = tempStep;
                            }
                          }
                          const newTempConfigData = { ...tempConfigData };
                          newTempConfigData.steps = [...tempSteps];
                          dispatch(SET_CONFIGURATOR_RULES(newTempConfigData));
                        }
                        if (disableStepsArray.length > 0) {
                          for (let m of tempSteps) {
                            if (disableStepsArray.includes(m.id)) {
                              const disableStepIndex = tempSteps.indexOf(m);
                              const tempStep = {
                                ...tempSteps[disableStepIndex],
                              };
                              tempStep.isHidden = true;
                              tempSteps[disableStepIndex] = tempStep;
                              // console.log(m, tempStep);
                            }
                          }
                          const newTempConfigData = { ...tempConfigData };
                          newTempConfigData.steps = [...tempSteps];
                          dispatch(SET_CONFIGURATOR_RULES(newTempConfigData));
                        }
                      }
                      if (
                        parameters[j].name?.toLowerCase() ===
                        selectedOption.name.toLowerCase()
                      ) {
                        const temp = { ...parameters[j] };
                        delete temp.name;

                        switch (customRules[i].type) {
                          case "replace_part":
                            if (selectedOption.name.toLowerCase() === "none") {
                              if (
                                CustomRuleNodes &&
                                CustomRuleNodes.length > 0
                              ) {
                                hideParts(CustomRuleNodes);
                              }
                            } else {
                              if (
                                CustomRuleNodes &&
                                CustomRuleNodes.length > 0
                              ) {
                                showParts(CustomRuleNodes);
                                try {
                                  if (parameters[j].glb) {
                                    await replacePartGlb(
                                      CustomRuleNodes,
                                      parameters[j].glb
                                    );
                                  }
                                } catch (error) {
                                  console.error(error);
                                }
                              }
                            }
                            break;
                          case "change_material":
                            for (const node of CustomRuleNodes) {
                              const {
                                textureUrl,
                                normalUrl,
                                metalnessUrl,
                                roughnessUrl,
                                alphaUrl,
                              } = parameters[j];
                              applyMaterial(
                                node,
                                textureUrl,
                                metalnessUrl,
                                normalUrl,
                                roughnessUrl,
                                alphaUrl
                              );
                            }
                            break;

                          case "hide_nodes":
                            for (const node of CustomRuleNodes) {
                              console.log(node, "hideNode");

                              hideNode(node);
                            }
                            break;
                          case "show_nodes":
                            for (const node of CustomRuleNodes) {
                              showNode(node);
                            }
                            break;
                          default:
                            break;
                        }
                      } else if (
                        parameters[j].name?.toLowerCase() ===
                        userConfiguration[
                          customRules[i].condition[0].id
                        ]?.name.toLowerCase()
                      ) {
                        switch (customRules[i].type) {
                          case "replace_part":
                            if (selectedOption.name.toLowerCase() === "none") {
                              if (
                                CustomRuleNodes &&
                                CustomRuleNodes.length > 0
                              ) {
                                hideParts(CustomRuleNodes);
                              }
                            } else {
                              if (
                                CustomRuleNodes &&
                                CustomRuleNodes.length > 0
                              ) {
                                showParts(CustomRuleNodes);
                                try {
                                  if (parameters[j].glb) {
                                    await replacePartGlb(
                                      CustomRuleNodes,
                                      parameters[j].glb
                                    );
                                  }
                                } catch (error) {
                                  console.error(error);
                                }
                              }
                            }
                            break;
                          case "change_material":
                            for (const node of CustomRuleNodes) {
                              const {
                                textureUrl,
                                normalUrl,
                                metalnessUrl,
                                roughnessUrl,
                                alphaUrl,
                              } = parameters[j];
                              applyMaterial(
                                node,
                                textureUrl,
                                metalnessUrl,
                                normalUrl,
                                roughnessUrl,
                                alphaUrl
                              );
                            }
                            break;
                          case "hide_nodes":
                            for (const node of CustomRuleNodes) {
                              console.log(node, "hideNode");

                              hideNode(node);
                            }
                            break;
                          case "show_nodes":
                            for (const node of CustomRuleNodes) {
                              showNode(node);
                            }
                            break;
                          default:
                            break;
                        }
                      }
                    }
                  }
                } else {
                  const optionConditions = [...customRules[i].condition];
                  const validateConditions = () => {
                    let validation = true;
                    for (let c of optionConditions) {
                      console.log(c, "option conditions");
                      if (
                        userConfiguration[c.id].name.toLowerCase() !==
                        c.name.toLowerCase()
                      ) {
                        console.log(
                          "validation failed",
                          userConfiguration[c.id].name.toLowerCase(),
                          c.name.toLowerCase()
                        );

                        validation = false;
                      }
                    }
                    return validation;
                  };
                  if (validateConditions()) {
                    const parameters = customRules[i].rules
                      ? customRules[i].rules
                      : customRules[i].parameters;
                    for (let j = 0; j < parameters.length; j++) {
                      if (
                        customRules[i].type === "show_hide_step" &&
                        userConfiguration[
                          customRules[i].condition[0].id
                        ].name.toLowerCase() ===
                          customRules[i].condition[0].name.toLowerCase()
                      ) {
                        const tempConfigData = { ...configData };
                        const tempSteps = [...tempConfigData.steps];
                        const enableStepsArray = parameters[j].enable_steps
                          ? parameters[j].enable_steps
                          : parameters[j].enableCustomizations;
                        const disableStepsArray = parameters[j].disable_steps
                          ? parameters[j].disable_steps
                          : parameters[j].disableCustomizations;
                        if (enableStepsArray.length > 0) {
                          for (let m of tempSteps) {
                            if (enableStepsArray.includes(m.id)) {
                              const enableStepIndex = tempSteps.indexOf(m);

                              const tempStep = {
                                ...tempSteps[enableStepIndex],
                              };
                              tempStep.isHidden = false;
                              tempSteps[enableStepIndex] = tempStep;
                            }
                          }
                          const newTempConfigData = { ...tempConfigData };
                          newTempConfigData.steps = [...tempSteps];
                          dispatch(SET_CONFIGURATOR_RULES(newTempConfigData));
                        }
                        if (disableStepsArray.length > 0) {
                          for (let m of tempSteps) {
                            if (disableStepsArray.includes(m.id)) {
                              const disableStepIndex = tempSteps.indexOf(m);
                              const tempStep = {
                                ...tempSteps[disableStepIndex],
                              };
                              tempStep.isHidden = true;
                              tempSteps[disableStepIndex] = tempStep;
                            }
                          }
                          const newTempConfigData = { ...tempConfigData };
                          newTempConfigData.steps = [...tempSteps];
                          dispatch(SET_CONFIGURATOR_RULES(newTempConfigData));
                        }
                      }
                      if (
                        parameters[j].name?.toLowerCase() ===
                        selectedOption.name.toLowerCase()
                      ) {
                        const temp = { ...parameters[j] };
                        delete temp.name;

                        switch (customRules[i].type) {
                          case "replace_part":
                            if (selectedOption.name.toLowerCase() === "none") {
                              if (
                                CustomRuleNodes &&
                                CustomRuleNodes.length > 0
                              ) {
                                hideParts(CustomRuleNodes);
                              }
                            } else {
                              if (
                                CustomRuleNodes &&
                                CustomRuleNodes.length > 0
                              ) {
                                showParts(CustomRuleNodes);
                                try {
                                  if (parameters[j].glb) {
                                    await replacePartGlb(
                                      CustomRuleNodes,
                                      parameters[j].glb
                                    );
                                  }
                                } catch (error) {
                                  console.error(error);
                                }
                              }
                            }
                            break;
                          case "change_material":
                            for (const node of CustomRuleNodes) {
                              const {
                                textureUrl,
                                normalUrl,
                                metalnessUrl,
                                roughnessUrl,
                                alphaUrl,
                              } = parameters[j];
                              applyMaterial(
                                node,
                                textureUrl,
                                metalnessUrl,
                                normalUrl,
                                roughnessUrl,
                                alphaUrl
                              );
                            }
                            break;
                          case "hide_nodes":
                            for (const node of CustomRuleNodes) {
                              console.log(node, "hideNode");

                              hideNode(node);
                            }
                            break;
                          case "show_nodes":
                            for (const node of CustomRuleNodes) {
                              showNode(node);
                            }
                            break;
                          default:
                            break;
                        }
                      } else if (
                        parameters[j].name?.toLowerCase() ===
                        userConfiguration[
                          customRules[i].condition[0].id
                        ]?.name.toLowerCase()
                      ) {
                        switch (customRules[i].type) {
                          case "replace_part":
                            if (selectedOption.name.toLowerCase() === "none") {
                              if (
                                CustomRuleNodes &&
                                CustomRuleNodes.length > 0
                              ) {
                                hideParts(CustomRuleNodes);
                              }
                            } else {
                              if (
                                CustomRuleNodes &&
                                CustomRuleNodes.length > 0
                              ) {
                                showParts(CustomRuleNodes);
                                try {
                                  if (parameters[j].glb) {
                                    await replacePartGlb(
                                      CustomRuleNodes,
                                      parameters[j].glb
                                    );
                                  }
                                } catch (error) {
                                  console.error(error);
                                }
                              }
                            }
                            break;
                          case "change_material":
                            for (const node of CustomRuleNodes) {
                              const {
                                textureUrl,
                                normalUrl,
                                metalnessUrl,
                                roughnessUrl,
                                alphaUrl,
                              } = parameters[j];
                              applyMaterial(
                                node,
                                textureUrl,
                                metalnessUrl,
                                normalUrl,
                                roughnessUrl,
                                alphaUrl
                              );
                            }
                            break;
                          case "hide_nodes":
                            for (const node of CustomRuleNodes) {
                              console.log(node, "hideNode");

                              hideNode(node);
                            }
                            break;
                          case "show_nodes":
                            for (const node of CustomRuleNodes) {
                              showNode(node);
                            }
                            break;
                          default:
                            break;
                        }
                      }
                    }
                  }
                }
              }
            }
          } else if (globalSteps[index].type === "part_selector") {
            if (globalSelectedOptions[index].name.toLowerCase() === "none") {
              if (nodes && nodes.length > 0) {
                console.log(nodes, "1079");
                hideParts(nodes);
              }
            } else {
              if (nodes && nodes.length > 0) {
                showParts(nodes);
                try {
                  if (rule.glb) {
                    await replacePartGlb(nodes, rule.glb);
                  }
                } catch (error) {
                  console.error(error);
                }
              }
            }
          } else if (globalSteps[index].type === "hide_show_part_selector") {
            if (globalSteps[index].id) {
              const isPartHidden =
                userConfiguration[globalSteps[index].id].name === "hide"
                  ? true
                  : false;
              if (isPartHidden) {
                hideParts(nodes);
              } else {
                showParts(nodes);
              }
            }
          }

          if (globalSteps[index].type === "variant_selector") {
            if (globalSelectedOptions[index].name.toLowerCase() === "none") {
              if (nodes && nodes.length > 0) {
                console.log(nodes, "1112");
                hideParts(nodes);
              }
            } else {
              if (nodes && nodes.length > 0) {
                showParts(nodes);
                try {
                  // await replaceSceneGlb(nodes, rule.glb);
                } catch (error) {
                  console.error(error);
                }
              }
            }
          }

          if (nodes && nodes.length > 0) {
            for (const node of nodes) {
              switch (globalSteps[index].type) {
                case "hide":
                  hideNode(node);
                  break;
                case "show":
                  showNode(node);
                  break;
                case "material_selector":
                  applyMaterial(
                    node,
                    rule.textureUrl,
                    rule.metalnessUrl,
                    rule.normalUrl,
                    rule.roughnessUrl
                  );
                  break;
                default:
                  break;
              }
            }
          }
        }

        if (currentStep.custom_json && JSON.parse(currentStep.custom_json)) {
          const customRules = JSON.parse(currentStep.custom_json).conditions
            ? [...JSON.parse(currentStep.custom_json).conditions]
            : [...JSON.parse(currentStep.custom_json)];

          for (let i = 0; i < customRules.length; i++) {
            const CustomRuleNodes: string[] =
              typeof customRules[i].node === "string"
                ? [customRules[i].node]
                : customRules[i].node;
            if (customRules[i].condition.length === 1) {
              if (
                customRules[i].condition[0]?.name ??
                userConfiguration[customRules[i].condition[0].id].name ===
                  customRules[i].condition[0].name
              ) {
                // console.log(customRules[i], `${i} test 1`);
                const parameters = customRules[i].rules
                  ? customRules[i].rules
                  : customRules[i].parameters;
                for (let j = 0; j < parameters.length; j++) {
                  if (
                    customRules[i].type === "show_hide_step" &&
                    userConfiguration[
                      customRules[i].condition[0].id
                    ].name.toLowerCase() ===
                      customRules[i].condition[0].name.toLowerCase()
                  ) {
                    const tempConfigData = { ...configData };
                    const tempSteps = [...tempConfigData.steps];
                    const enableStepsArray = parameters[j].enable_steps
                      ? parameters[j].enable_steps
                      : parameters[j].enableCustomizations;
                    const disableStepsArray = parameters[j].disable_steps
                      ? parameters[j].disable_steps
                      : parameters[j].disableCustomizations;
                    if (enableStepsArray.length > 0) {
                      for (let m of tempSteps) {
                        if (enableStepsArray.includes(m.id)) {
                          const enableStepIndex = tempSteps.indexOf(m);

                          const tempStep = { ...tempSteps[enableStepIndex] };
                          tempStep.isHidden = false;
                          tempSteps[enableStepIndex] = tempStep;
                        }
                      }
                      const newTempConfigData = { ...tempConfigData };
                      newTempConfigData.steps = [...tempSteps];
                      dispatch(SET_CONFIGURATOR_RULES(newTempConfigData));
                    }
                    if (disableStepsArray.length > 0) {
                      for (let m of tempSteps) {
                        if (disableStepsArray.includes(m.id)) {
                          const disableStepIndex = tempSteps.indexOf(m);
                          const tempStep = { ...tempSteps[disableStepIndex] };
                          tempStep.isHidden = true;
                          tempSteps[disableStepIndex] = tempStep;
                          // console.log(m, tempStep);
                        }
                      }
                      const newTempConfigData = { ...tempConfigData };
                      newTempConfigData.steps = [...tempSteps];
                      dispatch(SET_CONFIGURATOR_RULES(newTempConfigData));
                    }
                  }
                  if (
                    parameters[j].name?.toLowerCase() ===
                    selectedOption.name.toLowerCase()
                  ) {
                    const temp = { ...parameters[j] };
                    delete temp.name;

                    switch (customRules[i].type) {
                      case "replace_part":
                        if (selectedOption.name.toLowerCase() === "none") {
                          if (CustomRuleNodes && CustomRuleNodes.length > 0) {
                            hideParts(CustomRuleNodes);
                          }
                        } else {
                          if (CustomRuleNodes && CustomRuleNodes.length > 0) {
                            showParts(CustomRuleNodes);
                            try {
                              if (parameters[j].glb) {
                                await replacePartGlb(
                                  CustomRuleNodes,
                                  parameters[j].glb
                                );
                              }
                            } catch (error) {
                              console.error(error);
                            }
                          }
                        }
                        break;
                      case "change_material":
                        for (const node of CustomRuleNodes) {
                          const {
                            textureUrl,
                            normalUrl,
                            metalnessUrl,
                            roughnessUrl,
                            alphaUrl,
                          } = parameters[j];
                          applyMaterial(
                            node,
                            textureUrl,
                            metalnessUrl,
                            normalUrl,
                            roughnessUrl,
                            alphaUrl
                          );
                        }
                        break;
                      case "hide_nodes":
                        for (const node of CustomRuleNodes) {
                          console.log(node, "hideNode");

                          hideNode(node);
                        }
                        break;
                      case "show_nodes":
                        for (const node of CustomRuleNodes) {
                          showNode(node);
                        }
                        break;
                      default:
                        break;
                    }
                  } else if (
                    parameters[j].name?.toLowerCase() ===
                    userConfiguration[
                      customRules[i].condition[0].id
                    ]?.name.toLowerCase()
                  ) {
                    switch (customRules[i].type) {
                      case "replace_part":
                        if (selectedOption.name.toLowerCase() === "none") {
                          if (CustomRuleNodes && CustomRuleNodes.length > 0) {
                            hideParts(CustomRuleNodes);
                          }
                        } else {
                          if (CustomRuleNodes && CustomRuleNodes.length > 0) {
                            showParts(CustomRuleNodes);
                            try {
                              if (parameters[j].glb) {
                                await replacePartGlb(
                                  CustomRuleNodes,
                                  parameters[j].glb
                                );
                              }
                            } catch (error) {
                              console.error(error);
                            }
                          }
                        }
                        break;
                      case "change_material":
                        for (const node of CustomRuleNodes) {
                          const {
                            textureUrl,
                            normalUrl,
                            metalnessUrl,
                            roughnessUrl,
                            alphaUrl,
                          } = parameters[j];
                          applyMaterial(
                            node,
                            textureUrl,
                            metalnessUrl,
                            normalUrl,
                            roughnessUrl,
                            alphaUrl
                          );
                        }
                        break;
                      case "hide_nodes":
                        for (const node of CustomRuleNodes) {
                          console.log(node, "hideNode");

                          hideNode(node);
                        }
                        break;
                      case "show_nodes":
                        for (const node of CustomRuleNodes) {
                          showNode(node);
                        }
                        break;
                      default:
                        break;
                    }
                  }
                }
              }
            } else {
              const optionConditions = [...customRules[i].condition];
              const validateConditions = () => {
                let validation = true;
                for (let c of optionConditions) {
                  console.log(c, "option conditions");
                  if (
                    userConfiguration[c.id].name.toLowerCase() !==
                    c.name.toLowerCase()
                  ) {
                    console.log(
                      "validation failed",
                      userConfiguration[c.id].name.toLowerCase(),
                      c.name.toLowerCase()
                    );

                    validation = false;
                  }
                }
                return validation;
              };
              if (validateConditions()) {
                const parameters = customRules[i].rules
                  ? customRules[i].rules
                  : customRules[i].parameters;
                for (let j = 0; j < parameters.length; j++) {
                  if (
                    customRules[i].type === "show_hide_step" &&
                    userConfiguration[
                      customRules[i].condition[0].id
                    ].name.toLowerCase() ===
                      customRules[i].condition[0].name.toLowerCase()
                  ) {
                    const tempConfigData = { ...configData };
                    const tempSteps = [...tempConfigData.steps];
                    const enableStepsArray = parameters[j].enable_steps
                      ? parameters[j].enable_steps
                      : parameters[j].enableCustomizations;
                    const disableStepsArray = parameters[j].disable_steps
                      ? parameters[j].disable_steps
                      : parameters[j].disableCustomizations;
                    if (enableStepsArray.length > 0) {
                      for (let m of tempSteps) {
                        if (enableStepsArray.includes(m.id)) {
                          const enableStepIndex = tempSteps.indexOf(m);

                          const tempStep = { ...tempSteps[enableStepIndex] };
                          tempStep.isHidden = false;
                          tempSteps[enableStepIndex] = tempStep;
                        }
                      }
                      const newTempConfigData = { ...tempConfigData };
                      newTempConfigData.steps = [...tempSteps];
                      dispatch(SET_CONFIGURATOR_RULES(newTempConfigData));
                    }
                    if (disableStepsArray.length > 0) {
                      for (let m of tempSteps) {
                        if (disableStepsArray.includes(m.id)) {
                          const disableStepIndex = tempSteps.indexOf(m);
                          const tempStep = { ...tempSteps[disableStepIndex] };
                          tempStep.isHidden = true;
                          tempSteps[disableStepIndex] = tempStep;
                        }
                      }
                      const newTempConfigData = { ...tempConfigData };
                      newTempConfigData.steps = [...tempSteps];
                      dispatch(SET_CONFIGURATOR_RULES(newTempConfigData));
                    }
                  }

                  if (
                    parameters[j].name?.toLowerCase() ===
                    selectedOption.name.toLowerCase()
                  ) {
                    const temp = { ...parameters[j] };
                    delete temp.name;

                    switch (customRules[i].type) {
                      case "replace_part":
                        if (selectedOption.name.toLowerCase() === "none") {
                          if (CustomRuleNodes && CustomRuleNodes.length > 0) {
                            hideParts(CustomRuleNodes);
                          }
                        } else {
                          if (CustomRuleNodes && CustomRuleNodes.length > 0) {
                            showParts(CustomRuleNodes);
                            try {
                              if (parameters[j].glb) {
                                await replacePartGlb(
                                  CustomRuleNodes,
                                  parameters[j].glb
                                );
                              }
                            } catch (error) {
                              console.error(error);
                            }
                          }
                        }
                        break;
                      case "change_material":
                        for (const node of CustomRuleNodes) {
                          const {
                            textureUrl,
                            normalUrl,
                            metalnessUrl,
                            roughnessUrl,
                            alphaUrl,
                          } = parameters[j];
                          applyMaterial(
                            node,
                            textureUrl,
                            metalnessUrl,
                            normalUrl,
                            roughnessUrl,
                            alphaUrl
                          );
                        }
                        break;
                      case "hide_nodes":
                        for (const node of CustomRuleNodes) {
                          console.log(node, "hideNode");

                          hideNode(node);
                        }
                        break;
                      case "show_nodes":
                        for (const node of CustomRuleNodes) {
                          showNode(node);
                        }
                        break;
                      default:
                        break;
                    }
                  } else if (
                    parameters[j].name?.toLowerCase() ===
                    userConfiguration[
                      customRules[i].condition[0].id
                    ]?.name.toLowerCase()
                  ) {
                    switch (customRules[i].type) {
                      case "replace_part":
                        if (selectedOption.name.toLowerCase() === "none") {
                          if (CustomRuleNodes && CustomRuleNodes.length > 0) {
                            hideParts(CustomRuleNodes);
                          }
                        } else {
                          if (CustomRuleNodes && CustomRuleNodes.length > 0) {
                            showParts(CustomRuleNodes);
                            try {
                              if (parameters[j].glb) {
                                await replacePartGlb(
                                  CustomRuleNodes,
                                  parameters[j].glb
                                );
                              }
                            } catch (error) {
                              console.error(error);
                            }
                          }
                        }
                        break;
                      case "change_material":
                        for (const node of CustomRuleNodes) {
                          const {
                            textureUrl,
                            normalUrl,
                            metalnessUrl,
                            roughnessUrl,
                            alphaUrl,
                          } = parameters[j];
                          applyMaterial(
                            node,
                            textureUrl,
                            metalnessUrl,
                            normalUrl,
                            roughnessUrl,
                            alphaUrl
                          );
                        }
                        break;
                      case "hide_nodes":
                        for (const node of CustomRuleNodes) {
                          console.log(node, "hideNode");

                          hideNode(node);
                        }
                        break;
                      case "show_nodes":
                        for (const node of CustomRuleNodes) {
                          showNode(node);
                        }
                        break;
                      default:
                        break;
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    [
      applyMaterial,
      configData,
      currentStep,
      dispatch,
      hideNode,
      replacePartGlb,
      selectedOption,
      showNode,
      userConfiguration,
    ]
  );

  const server =
    process.env.REACT_APP_HELLOAR_STAGING === "true" ? "DEV" : "PROD";
  return (
    <div className="d-flex justify-content-center align-items-start viewerContainer">
      <div
        className={`align-items-center justify-content-center w-100 overflow-hidden viewerContainerChild
         ${isBottomBarOpen ? "h-50 h-md-100" : "h-100"}`}
        style={{
          maxHeight: `${isBottomBarOpen ?? "50vh"}`,
        }}
      >
        {data && (
          <Viewer3D ref={productViewerRef} data={data} server={server} />
        )}
      </div>
    </div>
  );
};

export default Preview;
